<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">Loading ...</span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">{{ dt.id }}</a>
      </td>
      <td>
        <span class="font-weight-normal">
          <router-link :to="`/settings/entries/kyc/${dt.id}`" class="text-blue">{{ dt.level }}</router-link>
          <!-- <span class="font-weight-normal">{{ dt.name }}</span> -->
        </span>
      </td>

      <td>
        <span class="font-weight-normal">{{ dt.cummulative_withdrawer_limit }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.daily_withdrawer_limit }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.description }}</span>
      </td>
      <td>
        <span class="font-weight-normal mx-1" v-for="req in dt.requirements" :key="req">
          {{ req }}
          <span :class="dt.requirements.length > 0 ? 'block': 'hidden'">,</span>
        </span>
      </td>

      <td>
        <span class="font-weight-normal">{{ dt.created_at }}</span>
      </td>

      <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <router-link :to="`/settings/entries/kyc/${dt.id}`" class="dropdown-item">
              <span class="fas fa-edit mr-1"></span>
              Update KYC
            </router-link>
            <!-- <a
              v-admin="100"
              class="dropdown-item text-danger"
              @click="
                  RequestConfirmation(
                    'Do you really want to delete this setting?', ()=> {
                      deleteResponse(dt.id)
                      
                    } 
                    
                  )
                "
            >
              <span class="fas fa-trash-alt mr-1"></span>
              Delete KYC
            </a>-->
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>
    
    <script lang="ts">
import { inject, ref, defineComponent } from "vue";
import { apiPost } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "ResponseRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const deleteResponse = (id: string) => {
      context.emit("delete-response", id);
    };

    const ToggleAdmin = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/user-account/${
            props.dt.type === "BASIC_USER" ? "add-admin" : "remove-admin"
          }`,
          {
            userId: props.dt.id,
          }
        );
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.type =
          props.dt.type === "BASIC_USER" ? "ADMIN_USER" : "BASIC_USER";
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      deleteResponse,
      ToggleAdmin,
    };
  },
});
</script>
    
    <style scoped></style>
    